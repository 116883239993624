import React from 'react';
import './AlertTemplate.css';
import ContentPathed from './ContentPathed';
import ButtonClose from './ButtonClose';
import Icon from './Icon';

const AlertTemplate = ({ style, options, message, close, ...test }) => {
  let className = 'container-alert';

  if (options.type) {
    className += ` container-alert-style-${options.type}`;
  }

  return (
    <div style={style} className={className}>
      <ContentPathed withoutBorders>
        {options.type === 'success' && (
          <Icon name="check" width="20px" style={{ alignSelf: 'center' }} />
        )}
        <div> {message}</div>

        <ButtonClose className="close" onClick={close} isGrey />
      </ContentPathed>
    </div>
  );
};

export default AlertTemplate;
