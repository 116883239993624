import * as React from 'react';
import { useState, useMemo, useEffect } from 'react';
// eslint-disable-next-line no-unused-vars
import Loading from '../components/Loading';

const AuthContext = React.createContext();

const deleteSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('userData');
};
function AuthProvider(props) {
  const [tokenLocal, setTokenLocal] = useState(null);
  const [userDataLocal, setUserDataLocal] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    function getLocalData() {
      const storedToken = localStorage.getItem('token');
      const storedUserDataLocal = localStorage.getItem('userData');
      setTokenLocal(storedToken);
      setUserDataLocal(storedUserDataLocal);
    }
    getLocalData();
  }, []);

  useEffect(() => {
    if (tokenLocal && userDataLocal) {
      setUserData(JSON.parse(userDataLocal));
    }
  }, [tokenLocal, userDataLocal]);

  const setToken = async token => {
    localStorage.setItem('token', token);
    await setTokenLocal(token);
  };

  // eslint-disable-next-line no-unused-vars
  const login = async (token, userdata) => {
    await setToken(token);
    localStorage.setItem('userData', JSON.stringify(userdata));

    await setUserData(userdata);
  };

  const logout = async () => {
    deleteSession();

    await setUserData(null);
  };

  const setUserSelected = async (userId, isTutor) => {
    localStorage.setItem(
      'userData',
      JSON.stringify({
        ...userData,
        userSelected: userId,
        isTutor
      })
    );
    await setUserData({
      ...userData,
      userSelected: userId,
      isTutor
    });
  };

  const isUserSelected = () => !!(userData && userData.userSelected);
  const isUserLogged = () => !!userData;

  const isTutor = () => {
    if (!isUserSelected()) {
      return false;
    }

    return userData.isTutor;
  };

  const isChild = () => {
    if (!isUserSelected()) {
      return false;
    }

    return !userData.isTutor;
  };

  const memoedValue = useMemo(
    () => ({
      userData,
      login,
      logout,
      isUserLogged,
      isUserSelected,
      setUserSelected,
      isTutor,
      setToken,
      isChild
    }),
    [userData]
  );

  return <AuthContext.Provider value={memoedValue} {...props} />;
}

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth, deleteSession };
