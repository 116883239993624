import React from 'react';
import Icon from './Icon';

const ButtonClose = ({ className, onClick, isGrey }) => (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div className={className || ''} onClick={onClick}>
    {isGrey && <Icon name="closeGrey" className="icon-remove" />}
    {!isGrey && <Icon name="close" className="icon-remove" />}
  </div>
);

export default ButtonClose;
