import './App.css';
import 'bulma/css/bulma.min.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ModalProvider } from 'react-modal-hook';

import React, { Suspense } from 'react';
import ReactPixel from 'react-facebook-pixel';

import routes, { RouteWrapper } from './config/Routes';
import AppProviders from './context/AppProviders';
import {
  APP_DEBUG,
  GTM_TAG,
  GTM_TAG_TEST,
  FACEBOOK_PIXEL_ID,
} from './config/App';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      // cacheTime: 1000 * 60 * 5, // default 5 minutes
      // staleTime: 0, // default 0
      cacheTime: 1000 * 60 * 15, // 10 minutes, cache data is considered stale after staleTime (refetch will be triggered)
      staleTime: 1000 * 60 * 5, // 5 minutes, new global staleTime will be used for all queries
    },
  },
});

const advancedMatching = {};
const options = {
  autoConfig: true,
  debug: false,
};

// testing
if (APP_DEBUG) {
  TagManager.initialize({
    gtmId: GTM_TAG_TEST,
  });
}
// production
if (!APP_DEBUG) {
  TagManager.initialize({
    gtmId: GTM_TAG,
  });
  ReactPixel.init(FACEBOOK_PIXEL_ID, advancedMatching, options);
}

function App() {
  return (
    <>
      <AppProviders>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <ModalProvider>
            <Suspense fallback={<div />}>
              <Router>
                <Switch>
                  {routes.map((route) => (
                    <RouteWrapper key={route.path} {...route} />
                  ))}
                </Switch>
              </Router>
            </Suspense>
          </ModalProvider>
        </QueryClientProvider>
      </AppProviders>
    </>
  );
}

export default App;
