import React from 'react';
import './Loading.css';
import Loader from 'react-loader-spinner';

const Loading = ({ color, centered, size, bigLoader }) => {
  const LoaderCmpnt = () => (
    <Loader
      type="TailSpin"
      color={color || '#99CC99'}
      height={size || 20}
      width={size || 20}
    />
  );

  if (centered) {
    return (
      <div className="columns">
        <div className="column is-3" />
        <div className="column is-6">
          <LoaderCmpnt />
        </div>
      </div>
    );
  }

  if (bigLoader) {
    return (
      <div
        className="has-text-centered"
        style={{
          width: '100%',
          margin: 'auto',
        }}
      >
        <Loader type="TailSpin" color="#99CC99" height="100" width="100" />
      </div>
    );
  }

  return <LoaderCmpnt />;
};

export default Loading;
