const API_URL = process.env.REACT_APP_API_URL;
const AGREEMENT_URL =
  'https://backoffice.mitapuy.org/api/terminos_y_condiciones';
const NAME_WEB = 'Tapuy: casa mágica';
const META_DESCRIPTION = 'Tapuy';
const GTM_TAG = 'GTM-KPTQP6Q';
const GTM_TAG_TEST = 'GTM-57S72RP';
const FACEBOOK_PIXEL_ID = '381285703453540';
const APP_DEBUG = process.env.REACT_APP_DEBUG === 'true';
const GOOGLE_RECAPTCHA_V3 = '6LeiRRQdAAAAAJxV2n-kEjxkNrK8KJxLaXJAaF2A';

export {
  API_URL,
  AGREEMENT_URL,
  NAME_WEB,
  META_DESCRIPTION,
  GTM_TAG,
  GTM_TAG_TEST,
  FACEBOOK_PIXEL_ID,
  APP_DEBUG,
  GOOGLE_RECAPTCHA_V3,
};
