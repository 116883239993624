import React from 'react';

import check from '../assets/icons/check.svg';
import littleCheck from '../assets/icons/little-check.svg';
import bars from '../assets/icons/bars.svg';
import radial from '../assets/icons/radial.svg';
import radialSelected from '../assets/icons/radial_selected.svg';
import calendar from '../assets/icons/calendar.svg';
import tooltip from '../assets/icons/tooltip.svg';
import eye from '../assets/icons/eye.svg';
import eyeWhite from '../assets/icons/eyeWhite.svg';
import checkbox from '../assets/icons/checkbox.svg';
import checkboxSelected from '../assets/icons/checkbox_selected.svg';
import configuration from '../assets/icons/configuration.svg';
import edit from '../assets/icons/edit.svg';
import edit2 from '../assets/icons/edit2.svg';
import hearth from '../assets/icons/hearth.svg';
import hearthWhite from '../assets/icons/hearthWhite.svg';
import follow from '../assets/icons/follow.svg';
import followWhite from '../assets/icons/followWhite.svg';
import music from '../assets/icons/music.svg';
import money from '../assets/icons/money.svg';
import puzzle from '../assets/icons/puzzle.svg';
import library from '../assets/icons/library.svg';
import home from '../assets/icons/home.svg';
import close from '../assets/icons/close.svg';
import closeGrey from '../assets/icons/closeGrey.svg';
import close2 from '../assets/icons/close2.svg';
import pack4 from '../assets/icons/pack4.svg';
import pack8 from '../assets/icons/pack8.svg';
import arte from '../assets/icons/arte.svg';
import construcciones from '../assets/icons/construcciones.svg';
import literatura from '../assets/icons/literatura.svg';
import logout from '../assets/icons/logout.svg';
import question from '../assets/icons/question.svg';
import myShopping from '../assets/icons/my-shopping.svg';
import lock from '../assets/icons/lock.svg';
import videoPlay from '../assets/icons/videoPlay.svg';
import circleGreen from '../assets/icons/circleGreen.svg';
import circleBlue from '../assets/icons/circleBlue.svg';
import circlePurple from '../assets/icons/circlePurple.svg';
import search from '../assets/icons/search.svg';
import badgePack from '../assets/icons/badgePack.svg';
import statistics from '../assets/icons/statistics.svg';
import profileChild from '../assets/icons/profile-child.svg';
import changeProfile from '../assets/icons/change-profile.png';
import informationWhite from '../assets/icons/information-white.svg';
import videos from '../assets/icons/videos.svg';
import gift from '../assets/icons/gift.svg';

import contactWebsite from '../assets/icons/contact-website.svg';
import contactPhone from '../assets/icons/contact-phone.svg';
import contactEmail from '../assets/icons/contact-email.svg';
import contactFacebook from '../assets/icons/contact-facebook.svg';
import contactInstagram from '../assets/icons/contact-instagram.svg';
import contactYoutube from '../assets/icons/contact-youtube.svg';

import like from '../assets/icons/like.svg';
import cart from '../assets/icons/cart.svg';
import cartWhite from '../assets/icons/cart-white.svg';

const ICONS = {
  radial,
  radialSelected,
  calendar,
  tooltip,
  eye,
  eyeWhite,
  checkbox,
  checkboxSelected,
  configuration,
  edit,
  hearth,
  hearthWhite,
  edit2,
  follow,
  followWhite,
  music,
  money,
  puzzle,
  library,
  home,
  close,
  closeGrey,
  pack4,
  pack8,
  arte,
  construcciones,
  literatura,
  logout,
  question,
  myShopping,
  lock,
  videoPlay,
  circleGreen,
  circleBlue,
  like,
  circlePurple,
  search,
  badgePack,
  statistics,
  profileChild,
  changeProfile,
  contactWebsite,
  contactEmail,
  contactPhone,
  contactFacebook,
  contactInstagram,
  contactYoutube,
  informationWhite,
  cart,
  cartWhite,
  videos,
  gift,
  bars,
  close2,
  check,
};

// eslint-disable-next-line react/prop-types,no-unused-vars
function Icon({ name, width, className, style, isValidPassword }) {
  // eslint-disable-next-line global-require
  return (
    <img
      src={isValidPassword ? littleCheck : ICONS[name]}
      alt="icon"
      className={`custom-icon icon-${name} ${className || ''}`}
      width={width || isValidPassword ? '15px' : ''}
      style={style || {}}
    />
  );
}

export default React.memo(Icon);
