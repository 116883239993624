import * as React from 'react';
import { useState, useMemo } from 'react';
// eslint-disable-next-line no-unused-vars
import Loading from '../components/Loading';

const KEY_LOCAL_STORAGE = 'order_id';

const CartContext = React.createContext();

const deleteCartFromLocal = () => {
  localStorage.removeItem(KEY_LOCAL_STORAGE);
};

function CartProvider(props) {
  const orderIdStorage = localStorage.getItem(KEY_LOCAL_STORAGE);

  const [orderId, setOrderIdState] = useState(orderIdStorage || null);

  // eslint-disable-next-line no-unused-vars
  const setOrderId = (orderIdData) => {
    localStorage.setItem(KEY_LOCAL_STORAGE, orderIdData);

    setOrderIdState(orderIdData);
  };

  const deleteCart = () => {
    deleteCartFromLocal();

    setOrderIdState(null);
  };

  const getOrderId = () => orderId;

  const memoedValue = useMemo(
    () => ({
      getOrderId,
      setOrderId,
      deleteCart,
    }),
    [orderId]
  );

  return <CartContext.Provider value={memoedValue} {...props} />;
}

const useCart = () => React.useContext(CartContext);

export { CartProvider, useCart, deleteCartFromLocal };
