import React from 'react';

import borderTop1 from '../assets/images/contentTop2.png';
import borderBottom1 from '../assets/images/contentBottom2.png';

const ContentPathed = ({
  classNames,
  children,
  style,
  background,
  withoutBorders,
}) => {
  let finalClassNames = 'container-content-pathed';
  if (classNames) {
    finalClassNames += ` ${classNames}`;
  }

  const styleBg = {
    zIndex: 99,
  };

  if (background) {
    styleBg.backgroundImage = `url(${background})`;
  }

  return (
    <>
      <div className={finalClassNames} style={style}>
        <div className="bg-pathed" style={styleBg}>
          {!withoutBorders && (
            <img src={borderTop1} alt="border-top" className="content-border" />
          )}
          <div className="content-pathed">{children}</div>
          {!withoutBorders && (
            <img
              src={borderBottom1}
              alt="border-top"
              className="content-border"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ContentPathed;
